import Vue from 'vue';
import iView from 'iview';
import i18n from './../locale';

import 'iview/dist/styles/iview.css';
import './../styl/variables.styl';

Vue.use(iView, {
    i18n: (key, value) => i18n.t(key, value),
});
